<template>
	<div class="auction-list">
		<div class="auction-list_nav">
			<my-nft-list-nav :index="current_index" :list="navList" @click="clickHandle"/>
		</div>
		<div class="MyNFTList-box">
			<div class="d-flex justify-center pt10" v-if="filter_list.length === 0">
				<div class="MyNFTList-nodata">{{ $t('You currently have no cards in this category. You can') }}
					<router-link to="/app/Game/BuyBox">{{ $t('buy boxes') }}</router-link>
					{{ $t('and get your NTF cards.') }}
				</div>
			</div>
			<div class="MyNFTList-card__box">
				<div v-for="(item, index) in filter_list" :key="index">
					<div class="MyNFTList-card__item">
						<img class="card-img" src="@/assets/open-bg.png" alt="">
						<img class="content-img" :src="getCardImgUrl(item.type)" alt="">
						<div class="MyNFTList-card__content">
							<div class="MyNFTList-card__head">
								<span class="MyNFTList-card__number">#{{ item.tokenId }}</span>
								<img class="combination-img" src="@/assets/combination_tag.png" alt="" v-show="cards[item.type].combined_list.length > 0">
							</div>
							<div class="MyNFTList-card__name">
								<div>{{ cards[item.type].zhHans }}</div>
								<div>{{ cards[item.type].en }}</div>
							</div>
						</div>
					</div>

					<div class="nft-price_text pb-0">{{ $t('Current BID') }}: {{ item.price }} AMT</div>
					<div class="nft-price_text" style="padding-top: 5px;">{{ $t('Buy Out Price') }}: {{ item.buyoutPrice }} AMT</div>

					<div class="text-center">
						<v-btn
								:style="[is_mobile ? mobile_btn_styles : pc_btn_styles]"
								color="#EB6930"
								large
								rounded
								@click="make(item)"
						>{{ item.isEnd ? $t('ENDED') : $t('MAKE A BID') }}</v-btn>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { auctionList } from '@/utils/cardFactory'
import MyNftListNav from '@/views/Game/MyNFT/MyNftListNav'
import cards from '@/data/card'
import { getCardImgUrl } from '@/utils/tools'
import { SessionStorage } from 'storage-manager-js'
import { mapState } from 'vuex'

export default {
	name: 'AuctionList',
	components: { MyNftListNav },
	data () {
		return {
			current_index: 0,
			navList: [],
			list: [],
			cards,
			mobile_btn_styles: {
				height: '34px !important',
				width: '111px',
			},
			pc_btn_styles: {
				height: '44px !important',
				width: '236px',
			},
		}
	},
	computed: {
		...mapState(['card_nav_list']),
		filter_list () {
			const current_type = this.navList[this.current_index].type
			const list = []
			if (current_type === 0) {
				return this.list
			} else {
				this.list.map(item => {
					const type = item.type.split('-')[0]
					if (current_type === Number(type)) {
						list.push(item)
					}
				})
			}
			return list
		},
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
	},
	created () {
		this.navList = this.navList = JSON.parse(JSON.stringify(this.card_nav_list))
		this.auctionList()
		const { index } = this.$route.query
		if (index) {
			this.current_index = Number(index)
		}
	},
	methods: {
		clickHandle ({ index }) {
			this.current_index = index
		},
		async auctionList () {
			this.list = await auctionList()
			this.list.map(item => {
				const type = item.type.split('-')[0]
				this.navList.map(nav => {
					if (nav.type === Number(type)) {
						nav.value++
					}
				})
			})

			this.navList[0].value = this.list.length
			SessionStorage.set('AUCTION_NAV', this.navList)
			// 0进行中 1:结束 2:取消
			// {
			// 	amount: '0'
			// 	auctionEnd: '1635387852' 结束时间，秒数
			// 	auctionState: '0' 状态
			// 	index: 0  序号
			// 	price: '1'  当前价格
			// 	seller: '0xa231159d3ef086a81338E046433Fee45D6827FD4'  卖家
			// 	startTime: '1634783052'  开始时间 秒数
			// 	token: '0xC449144941f2180729FbbA5B674EA6b0a690b037'
			// 	tokenId: '32375054'   tokenId
			// 	type: '1-14'  类型
			// 	winner: '0x0000000000000000000000000000000000000000'  当前出价最高者
			// }
		},
		getCardImgUrl (filename, type) {
			return getCardImgUrl(filename, type)
		},
		make (item) {
			const data = { ...item, data: this.cards[item.type] }
			SessionStorage.set('AUCTION_CARD', data)
			this.$router.push(`/app/AuctionDetail?index=${this.current_index}`)
		},
	},
}
</script>

<style lang="scss" scoped>
// pc
@media (min-width: 960px) {
	.auction-list {
		padding: 80px 0;
	}
}
// mobile
@media (max-width: 960px) {
	.auction-list {
		&_nav {
			padding: 0 25px;
		}
		padding: 25px 0;
	}
}
</style>
